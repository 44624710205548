<template>
  <!-- about START -->
  <div class="about">
    ABOUT
  </div>
  <!-- about END -->
</template>

<script>
export default {
  name: 'about',
};
</script>
